// src/store/category.js

import { defineStore, acceptHMRUpdate } from 'pinia';
import { http, auth } from './https';
import { AUTHTOKEN, CREATETOKEN, PHONE } from '@/constants';
export const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: localStorage.getItem(AUTHTOKEN) ? localStorage.getItem(AUTHTOKEN) : false,
        userInfo: {},
        lang: localStorage.getItem('selectedLocale'),
        error: null
    }),
    getters: {
        getIsloggedIn: (state) => state.isLoggedIn,
        getUserData: (state) => state.userInfo,
        getError: (state) => state.error

    },
    actions: {
        /**
         * subscribe action
         * @param phone
         * @response josn
         */
        subscribe(phone) {

            return new Promise((resolve, reject) => {
                http.post('/create/subscribtion', { phone: phone })
                    .then((response) => {
                        console.log(response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });


        },

        /**
         * confirm subscription action
         * @params phone
         * @param pin 
         * @param purchaseToken
         * @response json
         */
        confirmSubscription(pin) {
            let token = localStorage.getItem(CREATETOKEN);
            let phoneNumber = localStorage.getItem(PHONE);

            const data = {
                phone: phoneNumber,
                pin: pin,
                purchaseToken: token
            };

            return new Promise((resolve, reject) => {
                http.post('/confirm/subscribtion', data)
                    .then((response) => {
                        if (response.data.success) {
                            this.isLoggedIn = true;
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /**
         * login action
         * @param phone
         * @response json
         */

        async login(phone) {
            return new Promise((resolve, reject) => {
                http.post('/login/mondia', { phone: phone })
                    .then((response) => {
                        if (response.data.success) {
                            this.isLoggedIn = true;
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /**
         * logout action
         */
        async logOut() {
            this.isLoggedIn = false
            this.clearData();
        },
        /**
         * unsubscribe action 
         * @response json
         */
        async unsubscribe() {
            return new Promise((resolve, reject) => {
                auth.post('/unsubscribe/mondia')
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /***
         * fetching user info and favorite games 
         */
        async fetchUserInfo() {
            await auth.get(`/user/info/${this.lang}`)
                .then((response) => {
                    this.userInfo = response.data.subscriber
                    if (this.userInfo && this.userInfo.favorite) {
                        const favoriteData = JSON.stringify(this.userInfo.favorite);
                        localStorage.setItem('userFavorites', favoriteData);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx

                        this.error = error.response.status;


                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                });

        },
        async updateProfile(data) {
            return new Promise((resolve, reject) => {
                auth.post("/create/user/profile", data)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },

        async addToFavorite(gameId) {
            return new Promise((resolve, reject) => {
                auth.post(`/games/favorite/${gameId}`)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });

        },
        /***
         * set local storage data 
         * token and whatever needed 
         */
        setData(response) {
            localStorage.setItem(CREATETOKEN, response.data.payload);

            localStorage.setItem(PHONE, response.data.phone);

        },
        /**
         * clear local storage when user is logged out or unsubscribed 
         */
        clearData() {
            this.isLoggedIn = false
            localStorage.removeItem(PHONE);
            localStorage.removeItem(CREATETOKEN)
            localStorage.removeItem(AUTHTOKEN)
        }
    }

})


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useAuthStore, import.meta.webpackHot))
}