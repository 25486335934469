<template>
  <nav class=" fixed left-0 right-0 top-0  z-50  navbar hidden md:flex  lg:px-8  justify-between items-center opacity-5  "
    :style="{ backgroundColor: style }" :dir="isRtlLayout ? 'rtl' : 'ltr'">
    <!-- Logo -->
    <div class="flex items-center space-x-2">
      <router-link to="/"><img src="@/assets/yawyaw.png" alt="Logo" class="h-24   w-24  " /></router-link>
    </div>
    <!-- Search Bar -->
    <div class="flex items-center relative space-x-2  ">
      <!-- SVG Icon -->
      <search-view @click="fetchSearch" />

      <!-- Input Element -->
      <input type="text" :placeholder="`${$t('search_for_a_game')}`" v-model="query" @keyup.enter="fetchSearch"
        class="pl-8 px-2 lg:py-3 md:py-1.5 text-center border rounded-3xl focus:outline-none  focus:border-blue-300 lg:w-80 md:w-auto" />
      <div v-if="showResutls"
        class="absolute top-10 left-[0.5rem]  lg:mt-[2.2rem] mt-[0.6rem]  dropdown-bg border rounded-lg shadow-lg lg:w-80 md:w-auto">
        <!-- search results for the desktop -->
        <ul class="text-center text-white" :dir="isRtlLayout ? 'rtl' : 'ltr'">
          <li v-for="(i, index) in 6 " :key="index" class="flex justify-between items-center m-2">
            <div class="flex items-end space-x-3 ">
              <img :src="require('@/assets/mafia.webp')" class="w-16 h-16 rounded-md" alt="test">
              <strong class="text-white text-center ">game title</strong>
            </div>


            <div class="flex justify-end">

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#F4DA2D"
                class="w-4 h-4 ">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </div>

          </li>

        </ul>
        <!-- end of the search results -->
      </div>
    </div>


    <!-- Menu Items -->
    <div class="">
      <ul class="flex space-x-4 text-white">
        <li class="lg:mx-6 md:mx-2 text-lg ">
          <router-link to="/">{{ $t('home') }}</router-link>
          <div v-show="currentRouteName == 'home'" class="tab absolute bottom-0"></div>
        </li>
        <li class="lg:mx-6 md:mx-2 text-lg"><router-link to="/explore">{{ $t('discover') }}</router-link>
          <div v-show="currentRouteName == 'explore'" class="tab absolute bottom-0"></div>
        </li>
        <div class="relative">
          <li class="lg:mx-6 md:mx-2 text-lg cursor-pointer" @click="ToggleCats">{{ $t('categories') }}

          </li>
          <!-- dropdown menu for categories goes her -->
          <div v-if="isCategoriesOpened"
            class="absolute top-full left-[-3.5rem] w-56  lg:mt-[2.2rem] mt-[0.6rem]  dropdown-bg border rounded-lg shadow-lg"
            style="z-index: 2 !important;">
            <ul class="py-2">
              <!-- <div v-show="isCategoriesOpened" class="tab absolute right-1/3 -top-2"></div> -->
              <li v-for="(category, index) in getCategories" :key="index">
                <router-link :to="`/categories/details/${category.title}`"
                  class="block px-8 py-2 drop-down-text text-center cursor-pointer hover:dropdown-bg opacity-80 hover:opacity-100">{{
                    category.category_title
                  }}</router-link>

              </li>
            </ul>
          </div>

          <!-- end of the dropdown menu -->
        </div>

      </ul>
    </div>

    <!-- Login/Register -->
    <div class="flex space-x-6" v-if="!getIsloggedIn">
      <div class="mx-2 animate-bounce"><router-link to="/auth/subscribe"
          class="text-gray-900 bg-[#F4DA2D] rounded-md py-2.5 px-4 ">{{ $t("subscribe")
          }}</router-link></div>

      <div><router-link to="/auth/login" class="text-white"> {{ $t("login") }}</router-link></div>
    </div>
    <div class="flex space-x-6" v-else>
      <div class="mx-2 "><router-link to="/auth/user/profile" class="text-gray-200 text-md py-2.5 px-4 ">{{ $t("account")
      }}</router-link></div>

      <div><router-link to="/auth/logout" class="text-white"> {{ $t("logout") }}</router-link></div>
    </div>
    <!-- language switcher -->
    <div class="relative ml-3  " v-show="$route.name === 'home'">
      <!-- Language Switcher Button -->
      <lang-svg @click="toggleMenu" />
      <!-- <button @click="toggleMenu" class="px-2 py-1 bg-gray-200 rounded-lg">{{ selectedLanguage }}</button> -->

      <!-- Dropdown Menu for language switcher -->
      <div v-if="isMenuOpen"
        class="absolute top-full  lg:mt-[2.5rem] mt-[0.6rem]  dropdown-bg border rounded-lg shadow-lg "
        :class="isRtlLayout ? '-left-4' : '-right-4'" style="z-index: 2 !important;">
        <ul class="py-2">
          <li v-for="(language, index) in languages" :key="index">
            <span @click="setLanguage(language)"
              class="block px-8 py-2 drop-down-text cursor-pointer hover:dropdown-bg opacity-80 hover:opacity-100">{{
                language == 'ar_eg' ? 'عربي' : 'English'
              }}</span>
          </li>
        </ul>
      </div>
      <!-- Dropdown Menu for language switcher -->

    </div>
  </nav>
</template>

<script>
import SearchView from '@/components/svgs/SearchView.vue';
import LangSvg from '@/components/svgs/LangSvg.vue';
import { useLanguageStore } from '@/store/language';
import { useHomeStore } from '@/store/home';
import { useSearchStore } from '@/store/search';

import { mapActions, mapState } from 'pinia';
import { useAuthStore } from '@/store/auth';

export default {
  components: { SearchView, LangSvg },
  props: ['style'],
  inject: ['isRtlLayout'],
  data() {
    return {
      isMenuOpen: false,
      isCategoriesOpened: false,
      isSpeicalCats: false,
      selectedLanguage: 'ar_eg',
      languages: ['ar_eg', 'en'],
      showResutls: false,
      query: ''
    };
  },
  watch: {
    getIsloggedIn: {
      /* eslint-disable */
      handler(newValue) {

      },
      // force eager callback execution
      immediate: true
    }
  },
  computed: {
    ...mapState(useAuthStore, ['getIsloggedIn']),
    ...mapState(useHomeStore, ['getCategories']),

    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useLanguageStore, ['setLocale']),
    ...mapActions(useSearchStore, ['fetchSearchData']),

    setLanguage(locale) {
      this.$i18n.locale = locale;
      this.setLocale(locale);
    },
    async fetchSearch() {
      if (this.query == '') return;
      await this.fetchSearchData(this.query).then((response) => {
        if (response.data) {
          this.query = ''
          this.$router.push('/search/results')
        }
      });
    },
    ToggleCats(event) {
      this.isCategoriesOpened = !this.isCategoriesOpened;
      this.isSpeicalCats = false;
      this.isMenuOpen = false;
      event.stopPropagation(); // Prevent the click event from propagating further
    },
    ToggleSpeicalCats(event) {
      this.isSpeicalCats = !this.isSpeicalCats;
      this.isCategoriesOpened = false;
      this.isMenuOpen = false;
      event.stopPropagation(); // Prevent the click event from propagating further
    },
    toggleMenu(event) {
      this.isMenuOpen = !this.isMenuOpen;
      this.isCategoriesOpened = false
      this.isSpeicalCats = false;
      event.stopPropagation(); // Prevent the click event from propagating further
    },
    selectLanguage(language) {
      this.selectedLanguage = language;
      this.isMenuOpen = false; // Close the menu when a language is selected
      // You can add logic here to change the language of your app if needed.
    },
    closeMenuIfOutside(event) {
      // Check if the click event's target is not within the dropdown or the switcher button
      const dropdown = this.$el.querySelector(".absolute");


      if (!dropdown.contains(event.target)) {
        this.isMenuOpen = false;
        this.isCategoriesOpened = false;
        this.isSpeicalCats = false;
      }
    },
  },
  mounted() {


    // Add a global click event listener to close the menu when clicking outside
    window.addEventListener("click", this.closeMenuIfOutside);
  },
  beforeUnmount() {
    // Remove the event listener when the component is unmounted to prevent memory leaks
    window.removeEventListener("click", this.closeMenuIfOutside);
  },
};
</script>

<style scoped>
.navbar-copy {



  background-image: linear-gradient(119deg, #26084d 0%, #57214f 50%, #102e5f 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  z-index: 1000;
}
</style>