

<template>
  <!-- component -->
  <div class="md:hidden w-full mt-12 opacity-1 relative ">
    <section id="bottom-navigation" class="block fixed inset-x-0  bottom-0 z-50 p-3 navbar"
      :style="{ backgroundColor: style }">
      <div id="s" class="flex justify-between mx-4" :dir="isRtlLayout ? 'rtl' : 'ltr'">
        <router-link to="/" class="router-item outline-none foucs:!ring-0 focus:outline-none">

          <home-fill v-if="currentRouteName == 'home'" />
          <home-outline v-else />
          <span class="  block text-sm text-white" :class="currentRouteName == 'home' ? 'text-nav-a' : ''">{{
            $t("home") }}</span>
        </router-link>

        <router-link to="/explore" class="router-item outline-none foucs:!ring-0 focus:outline-none">
          <discover-fill v-if="currentRouteName == 'explore'" />
          <discover-outline v-else />

          <span class="  block text-sm text-white" :class="currentRouteName == 'explore' ? 'text-nav-a' : ''">{{
            $t("discover") }}</span>
        </router-link>

        <router-link to="/categories" class="router-item outline-none foucs:!ring-0 focus:outline-none">
          <categories-fill v-if="currentRouteName == 'categories'" />
          <categories-outline v-else />

          <span class="  block text-sm text-white" :class="currentRouteName == 'categories' ? 'text-nav-a' : ''">{{
            $t("categories") }}</span>
          <div v-show="currentRouteName == 'categories'" class="bg-nava-a nav-tab"></div>
        </router-link>

        <router-link to="/auth/subscribe" class="router-item outline-none foucs:!ring-0 focus:outline-none"
          v-if="!getIsloggedIn">
          <profile-fill v-if="currentRouteName == 'user.profile'" />
          <profile-outline v-else />
          <span class="  block text-sm text-white" :class="currentRouteName == 'user.profile' ? 'text-nav-a' : ''">{{
            $t("account") }}</span>
          <!-- <div v-show="currentRouteName === 'user.profile'" class="tap h-1 mt-2"></div> -->
        </router-link>
        <router-link to="/auth/user/profile" class="router-item outline-none foucs:!ring-0 focus:outline-none" v-else>
          <profile-fill v-if="currentRouteName == 'user.profile'" />
          <profile-outline v-else />
          <span class="  block text-sm text-white" :class="currentRouteName == 'user.profile' ? 'text-nav-a' : ''">{{
            $t("account") }}</span>
          <!-- <div v-show="currentRouteName === 'user.profile'" class="tap h-1 mt-2"></div> -->
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import DiscoverFill from '@/components/svgs/mainSvgs/DiscoverFill.vue';
import DiscoverOutline from '@/components/svgs/mainSvgs/DiscoverOutline.vue';

import CategoriesOutline from '@/components/svgs/mainSvgs/CategoriesOutline.vue';
import CategoriesFill from '@/components/svgs/mainSvgs/CategoriesFill.vue';
import HomeFill from '@/components/svgs/mainSvgs/HomeFill.vue';
import HomeOutline from '@/components/svgs/mainSvgs/HomeOutline.vue';
import ProfileFill from '@/components/svgs/mainSvgs/ProfileFill.vue';
import ProfileOutline from '@/components/svgs/mainSvgs/ProfileOutline.vue';
import { useAuthStore } from '@/store/auth';
import { mapState } from 'pinia';

export default {
  props: ['style'],
  inject: ['isRtlLayout'],

  components: {
    HomeFill,
    HomeOutline,
    DiscoverOutline,
    DiscoverFill,
    CategoriesOutline,
    ProfileFill,
    ProfileOutline,
    CategoriesFill,

  },

  data() {

    return {
      isActive: false,
      routeName: ['home', 'explore', 'categories', 'user.profile']
    };
  },

  watch: {
    getIsloggedIn: {
      /* eslint-disable */
      handler(newValue) {

      },
      // force eager callback execution
      immediate: true
    }
  },
  computed: {
    ...mapState(useAuthStore, ['getIsloggedIn']),
    currentRouteName() {
      return this.$route.name;
    },
  },

};
</script>

<style></style>














