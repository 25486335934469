export const TOKEN = 'token'
export const LOCAL = 'selectedLocale'
export const UPDATE = 'updateAvailable'
export const SHOWTUTORIAL = 'showTutorial'
export const MESSAGE="Subscription Created Successfully"
export const CREATETOKEN='createToken'
export const PHONE = 'phone'
export const AUTHTOKEN='authToken'
export const   icons= [
    'icon-1.png',
    'icon-2.png',
    'icon-3.png',
    'icon-4.png',
    'icon-5.png',
    'icon-6.png',
    'icon-7.png',
    'icon-8.png',
    'icon-1.png',
    'icon-2.png',
    'icon-3.png',
    'icon-4.png',
    'icon-5.png',
    'icon-6.png',
    'icon-7.png',
    'icon-8.png',
  ]