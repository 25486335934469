<template>
  <div class="min-h-screen flex items-center justify-center">
    <div class="p-8 rounded shadow-lg hover-scale">
      <img src="@/assets/yawyaw.png" alt="loader" class="w-32 h-32">
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped>
/* Define the animation */
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Scale up by 10% at the midpoint of the animation */
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the animation on hover */
.hover-scale img {
  animation: scaleAnimation 1s ease-in-out infinite; /* Adjust the animation duration as needed */
  transform-origin: center; /* Scale from the center */
}
</style>
