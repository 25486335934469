/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log("New content is downloading.");
      // Set a flag in localStorage to indicate an update is available
      localStorage.setItem("updateAvailable", true);
    },
    updated() {
      if (confirm(' تتوفر نسخة جديدة. هل تريد إعادة تحميل الصفحة للتحديث؟')) {
        // If the user confirms, refresh the page
        window.location.reload();
      }
      // if (this.registration && this.registration.waiting) {
      //   this.registration.waiting.postMessage({ action: "skipWaiting" });
      // }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

